import './PricingPlans.scss'

import type { ReactNode } from 'react'
import React from 'react'
import { useIsScreenLG } from '../../hooks'
import { useIsMobile } from '../../hooks/useIsMobile'
import PAPER_AIRPLANE from '../../images/svg/paper-airplane.svg'
import PRICING_PLANS from '../../images/svg/pricing-graphic.svg'

export const PricingPlans = () => {
	const isScreenLG = useIsScreenLG()
	const [isMobile, isIOS] = useIsMobile()

	const btnURL = isMobile
		? isIOS
			? 'https://apps.apple.com/us/app/id1543023229'
			: 'https://play.google.com/store/apps/details?id=com.eturi.motiv'
		: 'https://app.motiv.team'

	return (
		<div className="pricing-plans">
			<div className="pricing-plans__container">
				<div className="pricing-plans__img-container">
					<h2 className="pricing-plans__header">Access to all features & integrations</h2>

					<p className="pricing-plans__trial">Try Motiv free for 60 days.</p>

					<img src={PAPER_AIRPLANE} className="pricing-plans__plane-img" alt="Folded paper plane" />

					{isScreenLG && (
						<img
							src={PRICING_PLANS}
							className="pricing-plans__phone-img"
							alt="Phone displaying home screen with focus on Motiv app"
						/>
					)}
				</div>

				<div className="pricing-plans__subs">
					<PlanItem cycle="Billed Monthly" heading="Monthly Plan" price="$10 / seat" />

					<PlanItem
						cycle="For a limited time, sign up for the annual plan for 50% off ($5 / seat monthly, billed
							annually)"
						heading="Annual Plan"
						price="$5 / seat"
					/>

					<a className="pricing-plans__btn" href={btnURL} rel="noreferrer" target="_blank">
						{isMobile ? 'Download' : 'Sign Up'}
					</a>
				</div>
			</div>
		</div>
	)
}

type PlanItemProps = {
	readonly children?: ReactNode
	readonly cycle: string
	readonly heading: string
	readonly price: string
}

const PlanItem = (p: PlanItemProps) => (
	<div className="pricing-plans__sub-plan">
		<p className="pricing-plans__sub-plan-header">{p.heading}</p>
		<p className="pricing-plans__sub-plan-price">{p.price}</p>
		<p className="pricing-plans__sub-plan-cycle">{p.cycle}</p>

		{p.children}
	</div>
)
