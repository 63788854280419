import './Footer.scss'

import { Link } from 'gatsby'
import React from 'react'
import APP_LOGO from '../../images/svg/motiv-logo-tagline.svg'
import POWERED_BY_ETURI_LOGO from '../../images/svg/powered_by_eturi.svg'

export const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__logo-container">
				<Link className="footer__logo" to="/">
					<img src={APP_LOGO} className="footer__logo-img" alt="Motiv Logo" />
				</Link>

				<a
					className="footer__eturi-logo"
					href="https://www.eturi.com/"
					rel="noreferrer"
					target="_blank"
				>
					<img
						src={POWERED_BY_ETURI_LOGO}
						className="footer__eturi-logo-img"
						alt="Powered by Eturi"
					/>
				</a>
			</div>

			<div className="footer__links">
				<Link className="footer__nav-link" to="/about">
					About
				</Link>

				<a
					className="footer__nav-link"
					href="https://support.motiv.team/"
					rel="noreferrer"
					target="_blank"
				>
					FAQ
				</a>

				<Link className="footer__nav-link" to="/pricing">
					Pricing
				</Link>

				<a
					className="footer__nav-link"
					href="https://support.motiv.team/hc/en-us/requests/new?ticket_form_id=1500000087062"
					rel="noreferrer"
					target="_blank"
				>
					Contact
				</a>

				<a
					className="footer__nav-link"
					href="https://app.motiv.team/login"
					rel="noreferrer"
					target="_blank"
				>
					Login
				</a>

				<a
					className="footer__nav-link"
					href="https://app.motiv.team/signup"
					rel="noreferrer"
					target="_blank"
				>
					Sign Up
				</a>
			</div>

			<div className="footer__copyrights">
				<p className="footer__copyright">Copyright &copy; {new Date().getFullYear()} Eturi Corp.</p>

				<a className="footer__terms" href="https://app.motiv.team/terms.html">
					Terms of Use
				</a>

				<a className="footer__privacy" href="https://app.motiv.team/privacy.html">
					Privacy Policy
				</a>
			</div>
		</footer>
	)
}
