import React from 'react'
import { HomeCarousel } from './HomeCarousel'
import { HomeFeatures } from './HomeFeatures'
import { HomeHero } from './HomeHero'
import { HomeRoles } from './HomeRoles'
import { HomeSignUp } from './HomeSignUp'

export const Home = () => (
	<section className="home-page">
		<HomeHero />
		<HomeCarousel />
		<HomeFeatures />
		<HomeRoles />
		<HomeSignUp />
	</section>
)
