import './HomeSignUp.scss'

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { SignUp } from '../common'

export const HomeSignUp = () => (
	<section className="home-signup">
		<SignUp
			title="Create Your Motiv Account Today"
			description="Download the mobile app, sign up online and get started with Motiv to begin collecting important productivity metrics."
		/>

		<div className="home-signup__image-container">
			<StaticImage src="../../images/home-signup-phone.png" alt="Motiv Mobile App" />
		</div>
	</section>
)
