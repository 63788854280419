import './Download.scss'

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { SignUp } from '../common'

export const Download = () => (
	<section className="download-page-wrap">
		<article className="download-page">
			<SignUp
				title="Motiv is a Mobile Dashboard Providing Productivity Metrics on Your Team Leaders"
				description="With Motiv’s web and mobile app, CEOs, and leaders of distributed teams are able to track and organize the most important productivity metrics of their key managers. Integrate Motiv with Microsoft 365 and the Google Workspace apps your business uses every day. Download the mobile app and sign up to get started."
			/>

			<div className="download-page__img-wrap">
				<div className="download-page__img-wrap-2">
					<StaticImage
						alt="Hand holding phone, displaying Motiv app"
						className="download-page__img"
						objectFit="contain"
						src="../../images/download.png"
					/>
				</div>
			</div>
		</article>
	</section>
)
