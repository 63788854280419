import './HomeHero.scss'

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import APPLE_STORE_IMG from '../../images/AppStore.png'
import GOOGLE_PLAY_IMG from '../../images/PlayStore.png'

export const HomeHero = () => (
	<div className="home-hero">
		<div className="home-hero__content">
			<h1 className="home-hero__header">For a Limited Time, Try Motiv Free for 60-Days</h1>
			<p className="home-hero__desc">
				With the Motiv app, CEOs, managers and leaders of distributed teams will have productivity
				metrics for their Team Leaders in the palm of their hand. Integrate Motiv with Microsoft 365
				and the Google Workspace apps your business uses every day. Download our mobile app and sign
				up to get started!
			</p>{' '}
			<div className="home-hero__app-links">
				<a href="https://apps.apple.com/us/app/id1543023229" rel="noreferrer" target="_blank">
					<img className="home-hero__app-link" src={APPLE_STORE_IMG} alt="Apple Store Link" />
				</a>

				<a
					href="https://play.google.com/store/apps/details?id=com.eturi.motiv"
					rel="noreferrer"
					target="_blank"
				>
					<img alt="Google Play Store Link" className="home-hero__app-link" src={GOOGLE_PLAY_IMG} />
				</a>
			</div>
		</div>

		<div className="home-hero__image">
			<StaticImage
				alt="Two phones displaying Motiv app"
				objectFit="contain"
				objectPosition="bottom center"
				src="../../images/hero-img.png"
			/>
		</div>
	</div>
)
