import './Accordion.scss'

import { IOS_DEBOUNCE_SCROLL, useConstant, useDebounce, useFn, useWindowEvent } from '@eturi/react'
import { animated, useSpring } from '@react-spring/web'
import cls from 'classnames'
import type { ReactNode } from 'react'
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { unstable_batchedUpdates as bup } from 'react-dom'
import { v4 } from 'uuid'
import { useKeyboardClick } from '../../hooks'

type AccordionProps = {
	readonly children: ReactNode
	readonly independent?: boolean
}

type AccordionCtx = {
	// Whether the AccordionItem with the passed id is open
	readonly isOpen: (id: string) => boolean

	// Handles toggling an accordion item. Accepts optional override.
	readonly onToggle: (id: string, toggleOpen?: boolean) => void
}

const AccordionContext = createContext<AccordionCtx>(null as any)

export const Accordion = ({ children, independent = true }: AccordionProps) => {
	const [openIds, setOpenIds] = useState<Set<string>>(() => new Set())

	const isOpen = useFn((id: string) => openIds.has(id))
	const onToggle = useFn((id: string, toggleOpen?: boolean) => {
		const _isOpen = isOpen(id)

		// If toggleOpen is the same as _isOpen, we have the state we need.
		// Otherwise, we're going to flip to the correct state anyways.
		if (_isOpen === toggleOpen) return

		// If the accordion is independent, then multiple items can be open at the
		// same time. Otherwise, we create an empty set.
		const newOpenIds = independent ? new Set(openIds) : new Set<string>()

		// Then we either add or remove the id based on the toggle.
		_isOpen ? newOpenIds.delete(id) : newOpenIds.add(id)

		setOpenIds(newOpenIds)
	})

	// Only update / re-render when openIds is set.
	const value = useMemo(() => ({ isOpen, onToggle }), [openIds])

	return (
		<div className="accordion">
			<AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>
		</div>
	)
}

const ACCORDION_SPRING_CONFIG = {
	friction: 27,
	mass: 1,
	tension: 210,
}

const ACCORDION_CLOSED_PROPS = {
	config: ACCORDION_SPRING_CONFIG,
	height: '0px',
	opacity: 0,
}

type AccordionItemProps = {
	readonly children?: ReactNode
	readonly defaultOpen?: boolean
	readonly title?: string | ReactNode
}

export const AccordionItem = ({ children, defaultOpen, title }: AccordionItemProps) => {
	// Each AccordionItem instance creates a unique id. This way it's not required
	// to pass and id via props.
	const id = useConstant(v4)
	const c = useContext(AccordionContext)
	const contentRef = useRef<HTMLDivElement>(null)
	const isOpen = c.isOpen(id)

	// Props for spring states done via get / set for rendering perf.
	const getChevronSpringProps = useFn(() => ({
		config: ACCORDION_SPRING_CONFIG,
		transform: `rotate(${isOpen ? 180 : 0}deg)`,
	}))

	const getContentSpringProps = useFn(() => {
		// It's possible for this to be undefined since accordion is lazy loaded.
		const $c = contentRef.current

		if (!isOpen || !$c) return ACCORDION_CLOSED_PROPS

		// We have to remove the currently set height. If this is called on resize,
		// the height will already be set and it may be greater than scrollHeight.
		$c.style.height = ''

		return {
			config: ACCORDION_SPRING_CONFIG,
			height: `${$c.scrollHeight}px`,
			opacity: 1,
		}
	})

	const [chevronSpringProps, setChevronSpringProps] = useSpring(getChevronSpringProps)
	const [contentSpringProps, setContentSpringProps] = useSpring(getContentSpringProps)

	// Set both spring props via their getters
	const setSpringProps = useFn(() =>
		bup(() => {
			setChevronSpringProps(getChevronSpringProps())
			setContentSpringProps(getContentSpringProps())
		}),
	)

	const handleClick = useKeyboardClick(() => c.onToggle(id))

	useWindowEvent(
		'resize',
		useDebounce(() => {
			// We don't need to measure items that aren't open.
			if (!isOpen) return

			// Re-set the spring props on resize in case the height changes
			setSpringProps()
		}, IOS_DEBOUNCE_SCROLL),
	)

	useEffect(() => {
		// Open if this should be open by default
		if (defaultOpen) c.onToggle(id, true)

		// All we need to do to clean up is close the item, which removes the id
		return () => {
			c.onToggle(id, false)
		}
	}, [])

	useEffect(setSpringProps, [isOpen])

	return (
		<div className={cls('accordion-item', isOpen && 'accordion-item--active')}>
			<header className="accordion-item__title" {...handleClick}>
				{title}
				<animated.i className="accordion-item__chevron m-chevron-down" style={chevronSpringProps} />
			</header>

			<animated.div
				className="accordion-item__content-wrap"
				ref={contentRef}
				style={contentSpringProps}
			>
				<div className="accordion-item__content">{children}</div>
			</animated.div>
		</div>
	)
}
