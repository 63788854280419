import './Pricing.scss'

import React from 'react'
import { useIsScreenMD } from '../../hooks'
import { PricingFeatures } from './PricingFeatures'
import { PricingPlans } from './PricingPlans'
import { PricingTeamData } from './PricingTeamData'

export const Pricing = () => {
	const isScreenMD = useIsScreenMD()

	return (
		<div className="pricing-page">
			<div className="pricing-page__hero">
				<h1 className="pricing-page__header">Flexible Plans to Fit Your Business Needs</h1>
				{isScreenMD && <p>Get productivity metrics for your leaders and distributed teams</p>}
			</div>

			<PricingFeatures />

			<PricingPlans />

			<PricingTeamData />
		</div>
	)
}
