import './SignUp.scss'

import React from 'react'
import APPLE_STORE_IMG from '../../images/AppStore.png'
import GOOGLE_PLAY_IMG from '../../images/PlayStore.png'

type SignUpProps = {
	readonly title: string
	readonly description: string
}

export const SignUp = ({ title, description }: SignUpProps) => (
	<div className="signup">
		<h1 className="signup__heading">{title}</h1>

		<p>{description}</p>

		<div className="signup__links">
			<a
				className="signup__btn signup__link"
				href="https://app.motiv.team/signup"
				rel="noreferrer"
				target="_blank"
			>
				Sign Up
			</a>

			<a
				className="signup__link"
				href="https://apps.apple.com/us/app/id1543023229"
				rel="noreferrer"
				target="_blank"
			>
				<img src={APPLE_STORE_IMG} alt="Apple Store Link" />
			</a>

			<a
				className="signup__link"
				href="https://play.google.com/store/apps/details?id=com.eturi.motiv"
				rel="noreferrer"
				target="_blank"
			>
				<img src={GOOGLE_PLAY_IMG} alt="Google Play Store Link" />
			</a>
		</div>
	</div>
)
