import './HomeCarousel.scss'

import {
	IOS_DEBOUNCE_SCROLL,
	PASSIVE_CAPTURE,
	useDebounce,
	useStateGetter,
	useWindowEvent,
} from '@eturi/react'
import { a, useSpring } from '@react-spring/web'
import cls from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { unstable_batchedUpdates as bup } from 'react-dom'
import { useIsInit, useIsScreenLG } from '../../hooks'
import { HomeMobileCarousel } from './HomeMobileCarousel'

export const HomeCarousel = () => {
	const [activeIdx, setActiveIdx] = useState(0)
	const imgContainerRef = useRef<HTMLDivElement>(null)
	const [imgElWidth, setImgElWidth] = useStateGetter(0)
	const isInit = useIsInit()
	const isScreenLG = useIsScreenLG()

	const [{ x }, setImgSpring] = useSpring(() => ({
		config: { clamp: true },
		x: 0,
	}))

	const calcAndSetX = () => {
		setImgSpring({ x: -1 * (imgElWidth() * activeIdx) })
	}

	const measure = () => {
		setImgElWidth(imgContainerRef.current?.offsetWidth || 0)
	}

	useEffect(calcAndSetX, [activeIdx])
	useEffect(measure, [imgContainerRef.current])

	useWindowEvent(
		'resize',
		useDebounce(() => {
			bup(() => {
				measure()
				calcAndSetX()
			})
		}, IOS_DEBOUNCE_SCROLL),
		PASSIVE_CAPTURE,
	)

	// When the screen is large we can just display the carousel inline without controls
	const CarouselMenu = useMemo(
		() =>
			isScreenLG || !isInit ? (
				<div className="home-carousel__tabs">
					<div
						className={cls('home-carousel__tab', activeIdx === 0 && 'home-carousel__tab--active')}
						onClick={() => setActiveIdx(0)}
					>
						<p className="home-carousel__tab-header">Create & Integrate</p>
						<p>Motiv links directly with Google Workspace and Microsoft 365.</p>
					</div>

					<div
						className={cls('home-carousel__tab', activeIdx === 1 && 'home-carousel__tab--active')}
						onClick={() => setActiveIdx(1)}
					>
						<p className="home-carousel__tab-header">Invite Team Leaders</p>
						<p>Use the web app to add your leads into Motiv.</p>
					</div>

					<div
						className={cls('home-carousel__tab', activeIdx === 2 && 'home-carousel__tab--active')}
						onClick={() => setActiveIdx(2)}
					>
						<p className="home-carousel__tab-header">Create & Assign Teams</p>
						<p>Create your team and assign team leaders.</p>
					</div>

					<div
						className={cls('home-carousel__tab', activeIdx === 3 && 'home-carousel__tab--active')}
						onClick={() => setActiveIdx(3)}
					>
						<p className="home-carousel__tab-header">Download The App</p>
						<p>Download the Motiv mobile app and get going!</p>
					</div>
				</div>
			) : (
				<HomeMobileCarousel activeIdx={activeIdx} setActiveIdx={setActiveIdx} />
			),
		[isScreenLG, activeIdx, isInit],
	)

	return (
		<section className="home-carousel">
			<div className="home-carousel__top">
				<h4>How It Works</h4>
				<h2 className="home-carousel__desc">
					Integrate Your Collaboration Tools &amp; Automatically Collate Your Team Leader
					Productivity
				</h2>
			</div>

			{CarouselMenu}

			<div className="home-carousel__image-container">
				<a.div
					className="home-carousel__images"
					ref={imgContainerRef}
					style={{ transform: x.to((x: number) => `translate(${x}px, 0px)`) }}
				>
					<div className="home-carousel__image">
						<StaticImage
							alt="Integration Options UI"
							objectFit="contain"
							src="../../images/carousel-1.png"
						/>
					</div>

					<div className="home-carousel__image">
						<StaticImage
							alt="Team Management UI"
							objectFit="contain"
							src="../../images/carousel-2.png"
						/>
					</div>

					<div className="home-carousel__image">
						<StaticImage alt="Team List UI" objectFit="contain" src="../../images/carousel-3.png" />
					</div>

					<div className="home-carousel__image">
						<StaticImage alt="Mobile App" objectFit="contain" src="../../images/carousel-4.png" />
					</div>
				</a.div>
			</div>
		</section>
	)
}
