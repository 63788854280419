import { useFn, useMounted } from '@eturi/react'
import { useLayoutEffect, useState } from 'react'
import { AppMediaBrkPt, AppMediaBrkPts } from '../css-variables'

export type MediaQueryData = {
	readonly matches: boolean
	readonly media: string
}

export type MediaQueryListener = (mq: MediaQueryData) => void
export type MediaResizeListener = (brkPt: number) => void

type MediaQueryEventListener = (ev: MediaQueryListEvent) => any | void

export const mediaWidthStr = (minBrkPt?: number, maxBrkPt?: number): string => {
	const q = ['screen']

	if (minBrkPt != null) q.push(`(min-width: ${minBrkPt}px)`)
	if (maxBrkPt != null) q.push(`(max-width: ${maxBrkPt}px)`)

	return q.join(' and ')
}

const addQlChange = (ql: MediaQueryList, listener: MediaQueryEventListener) => {
	ql.addEventListener ? ql.addEventListener('change', listener) : ql.addListener(listener)
}

const remQlChange = (ql: MediaQueryList, listener: MediaQueryEventListener) => {
	ql.removeEventListener ? ql.removeEventListener('change', listener) : ql.removeListener(listener)
}

const _getHighestBrkPtMatch = (): number => {
	// NOTE These checks are here for builds. During builds on the server, window is not available so
	//  they will fail. This tells the builds to ignore this code if window is not available, however
	//  when this code runs on the client it will find the window object and be able to run without
	//  issues
	if (typeof window === 'undefined') return 0

	// AppMediaBrkPts are sorted highest to lowest, so as soon as we find a match
	// we can return it
	for (const bp of AppMediaBrkPts) {
		if (window.matchMedia(mediaWidthStr(bp)).matches) return bp
	}

	return 0
}

let highestBrkPtMatch = _getHighestBrkPtMatch()

export const getHighestBrkPtMatch = () => highestBrkPtMatch

const listeners = new Set<MediaResizeListener>()

AppMediaBrkPts.forEach((bp) => {
	if (typeof window === 'undefined') return

	const ql = window.matchMedia(mediaWidthStr(bp))

	addQlChange(ql, () => {
		// On change we always update the highest match
		highestBrkPtMatch = _getHighestBrkPtMatch()
		listeners.forEach((l) => l(highestBrkPtMatch))
	})
})

const _useMediaBrkPt = (query: string, listener: MediaQueryListener) => {
	const immutableListener = useFn((ev: MediaQueryListEvent) => {
		listener({ media: ev.media, matches: ev.matches })
	})

	useLayoutEffect(() => {
		if (typeof window === 'undefined') return

		const ql = window.matchMedia(query)

		listener({ media: ql.media, matches: ql.matches })

		addQlChange(ql, immutableListener)
		return () => remQlChange(ql, immutableListener)
	}, [])
}

export const matchesMediaWidth = (minBrkPt?: number, maxBrkPt?: number) =>
	window.matchMedia(mediaWidthStr(minBrkPt, maxBrkPt)).matches

export const useMediaMinWidth = (minBrkPt: number, listener: MediaQueryListener) =>
	_useMediaBrkPt(mediaWidthStr(minBrkPt), listener)

export const useMediaMaxWidth = (maxBrkPrt: number, listener: MediaQueryListener) =>
	_useMediaBrkPt(mediaWidthStr(undefined, maxBrkPrt), listener)

export const useMediaMinMaxWidth = (
	minBrkPt: number,
	maxBrkPt: number,
	listener: MediaQueryListener,
) => _useMediaBrkPt(mediaWidthStr(minBrkPt, maxBrkPt), listener)

export const matchesMobileSize = () => matchesMediaWidth(undefined, AppMediaBrkPt.LG)

export const useMediaResize = (listener: MediaResizeListener) => {
	const immutableListener = useFn(listener)

	useLayoutEffect(() => {
		listeners.add(immutableListener)
		return () => {
			listeners.delete(immutableListener)
		}
	}, [])
}

export const useHighestMediaBrkPt = (): number => {
	const [brkPt, setBrkPt] = useState(getHighestBrkPtMatch)
	const isMounted = useMounted()

	useMediaResize((v) => {
		if (typeof window === 'undefined') return

		window.requestAnimationFrame(() => isMounted() && setBrkPt(v))
	})

	return brkPt
}

export const useIsScreenXS = () => useHighestMediaBrkPt() < AppMediaBrkPt.XS
export const useIsScreenSM = () => useHighestMediaBrkPt() >= AppMediaBrkPt.SM
export const useIsScreenMD = () => useHighestMediaBrkPt() >= AppMediaBrkPt.MD
export const useIsScreenLG = () => useHighestMediaBrkPt() >= AppMediaBrkPt.LG
export const useIsScreenXL = () => useHighestMediaBrkPt() >= AppMediaBrkPt.XL
export const useIsScreenXXL = () => useHighestMediaBrkPt() >= AppMediaBrkPt.XXL
export const useIsScreenMobile = () => !useIsScreenMD()
