import './HomeRoles.scss'

import { useConstant } from '@eturi/react'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import { useIsInit, useIsScreenLG } from '../../hooks'
import { Accordion, AccordionItem } from '../Accordion/Accordion'

export const HomeRoles = () => {
	const isScreenLG = useIsScreenLG()
	const isInit = useIsInit()

	const CEO_TITLE = useConstant(() => (
		<div className="home-roles__title-container">
			<p className="home-roles__title">CEO's & Executives</p>
		</div>
	))

	const MANAGERS_TITLE = useConstant(() => (
		<div className="home-roles__title-container">
			<p className="home-roles__title">Managers & Team Leads</p>
		</div>
	))

	const REMOTE_TITLE = useConstant(() => (
		<div className="home-roles__title-container">
			<p className="home-roles__title">Remote Teams</p>
		</div>
	))

	const DESKTOP_INFO = useMemo(
		() =>
			(isScreenLG || !isInit) && (
				<Accordion independent={false}>
					<AccordionItem defaultOpen={true} title={CEO_TITLE}>
						<p>
							You need access to productivity metrics to make the most informed decisions for your
							business. That’s why the Motiv dashboard delivers email and meeting metrics right to
							your phone or desktop.
						</p>
					</AccordionItem>

					<AccordionItem title={MANAGERS_TITLE}>
						<p>
							Download the Motiv app to see firsthand how well your team is working. Ensure team
							leaders are practicing good time management and proactive communication habits.
						</p>
					</AccordionItem>

					<AccordionItem title={REMOTE_TITLE}>
						<p>
							Distributed teams are known for their flexibility. In order to effectively monitor
							remote team leaders and assess their productivity, you need a tool just as adaptable.
							Motiv allows you to receive metrics on email and meeting activity from anywhere.
						</p>
					</AccordionItem>
				</Accordion>
			),
		[isScreenLG, isInit],
	)

	const MOBILE_INFO = useMemo(
		() =>
			!isScreenLG &&
			isInit && (
				<>
					{CEO_TITLE}
					<div className="home-roles__mobile-info">
						<p className="home-roles__mobile-desc">
							You need access to productivity metrics to make the most informed decisions for your
							business. That’s why the Motiv dashboard delivers email and meeting metrics right to
							your phone or desktop.
						</p>
					</div>

					{MANAGERS_TITLE}
					<div className="home-roles__mobile-info">
						<p className="home-roles__mobile-desc">
							Download the Motiv app to see firsthand how well your team is working. Ensure team
							leaders are practicing good time management and proactive communication habits.
						</p>
					</div>

					{REMOTE_TITLE}
					<div className="home-roles__mobile-info">
						<p className="home-roles__mobile-desc">
							Distributed teams are known for their flexibility. In order to effectively monitor
							remote team leaders and assess their productivity, you need a tool just as adaptable.
							Motiv allows you to receive metrics on email and meeting activity from anywhere.
						</p>
					</div>
				</>
			),
		[isScreenLG, isInit],
	)

	return (
		<section className="home-roles">
			<StaticImage
				alt="Bubbles w/ arrows showing how people interact across within and across teams"
				className="home-roles__graphic"
				objectPosition="0 0"
				objectFit="contain"
				src="../../images/role-bubbles.png"
			/>

			<div className="home-roles__info">
				<h4 className="home-roles__heading">Is This You?</h4>

				<h2 className="home-roles__sub-heading"> Best for Leaders of Distributed Teams </h2>

				<div className="home-roles__list">
					{MOBILE_INFO}
					{DESKTOP_INFO}
				</div>
			</div>
		</section>
	)
}
