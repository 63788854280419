import values from 'lodash/values'
import * as v from './styles/exports.module.scss'

const toInt = (v: string): number => /* @__PURE__ */ Number.parseInt(v, 10)

export const AppMediaBrkPt = {
	XS: /* @__PURE__ */ toInt(v.xs),
	SM: /* @__PURE__ */ toInt(v.sm),
	MD: /* @__PURE__ */ toInt(v.md),
	LG: /* @__PURE__ */ toInt(v.lg),
	XL: /* @__PURE__ */ toInt(v.xl),
	XXL: /* @__PURE__ */ toInt(v.xxl),
}

// Reverse sort breakpoints so we can match from highest to lowest
export const AppMediaBrkPts = /* @__PURE__ */ values(AppMediaBrkPt).sort((a, b) =>
	a > b ? -1 : b > a ? 1 : 0,
)
