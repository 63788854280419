import './PageWrap.scss'

import type { ReactNode } from 'react'
import React from 'react'
import { Footer } from '../Footer'
import { NavBar } from '../NavBar'

type PageWrapProps = {
	readonly children: ReactNode
	readonly className?: string
	readonly omitFooter?: boolean
	readonly omitNav?: boolean
}

export const PageWrap = ({
	children,
	className,
	omitFooter = false,
	omitNav = false,
}: PageWrapProps) => {
	return (
		<main className="page-wrap">
			{!omitNav && <NavBar className={className} />}

			<div className="page-wrap__content">{children}</div>

			{!omitFooter && <Footer />}
		</main>
	)
}
