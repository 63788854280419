import './HomeFeatures.scss'

import { useConstant } from '@eturi/react'
import { graphql, useStaticQuery } from 'gatsby'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'

type FeatureData = {
	readonly alt: string
	readonly desc: string
	readonly fileName: string
	readonly title: string
}

// NOTE: This component shows use of dynamic multi-image query. It shows the
//  strengths and weaknesses of such an approach.
//  -
//  Strengths are that we can pass image data / URI as props and still get the
//  optimizations of the gatsby-plugin-image. As w/ all Gatsby queries, it
//  performs its queries at build time so the data already exists and all the
//  images get built and optimized at various sizes and formats.
//  -
//  The disadvantages are that the regex query is fallible, so if we have
//  static data to associate, we have to match on the original filename, which
//  we add to the query. Normally we'd just use `StaticImage`, but that
//  component doesn't allow for `src` to be passed from props. So you can't
//  wrap a `StaticImage` in a component that requires src from props. I wanted
//  to see what it would take to build discrete, reusable components that
//  require the image as props.
//  -
//  The other disadvantage is w/ GraphQL in general, which is that the
//  structured queries require tedious typing. In this case they are any typed.
export const HomeFeatures = () => {
	const featureImgRes = useStaticQuery(graphql`
  {
    allImageSharp(filter: { fluid: { originalName: {regex: "/feature-\\d+\\.png/"} } }) {
      edges {
        node {
          gatsbyImageData
          fluid {  originalName }
        }
      }
    }
  }
`)

	const data = useConstant((): FeatureData[] => [
		{
			alt: `Image of Google and Microsoft logos together`,
			desc: `Motiv integrates with the collaboration tools you use everyday such as Gmail and Microsoft Outlook.`,
			fileName: `feature-1.png`,
			title: `Synced with Collaboration Tools`,
		},

		{
			alt: `Image of two team members' meeting hours on bar graphs`,
			desc: `View insightful analytics into audio/video conference calls.`,
			fileName: `feature-2.png`,
			title: `Unique Insights on Meetings`,
		},

		{
			alt: `Image of emails sent displayed next to line graph showing emails over time`,
			desc: `Discover how many business emails are sent and received, and observe week-over-week trends.`,
			fileName: `feature-3.png`,
			title: `Email Overview`,
		},

		{
			alt: `Image showing list of team names`,
			desc: `Track scheduled and attended time; determine if meetings are being over scheduled.`,
			fileName: `feature-4.png`,
			title: `Greater Insights into Google Meets Data`,
		},

		{
			alt: `Image showing lock and other security iconography`,
			desc: `Motiv has no plug-ins or downloads to company computers for maximum user privacy.`,
			fileName: `feature-5.png`,
			title: `Unparalleled Privacy and Security`,
		},

		{
			alt: `Motiv app on phone in hand, showing Apple and Android logos`,
			desc: `Download the Motiv mobile app from the App Store and Google Play, and access powerful analytics on the go.`,
			fileName: `feature-6.png`,
			title: `Available for Apple and Android`,
		},
	])

	const featureImgData = useMemo(() => {
		const edges: any[] = featureImgRes?.allImageSharp?.edges || []

		return data
			.map((d) => {
				const v = edges.find((e) => d.fileName === e.node.fluid.originalName)

				if (!v) return null

				const img = getImage(v.node)

				if (!img) return null

				return { ...d, img }
			})
			.filter((v): v is FeatureData & { readonly img: IGatsbyImageData } => !!v)
	}, [featureImgRes])

	return (
		<section className="home-features">
			<h2 className="home-features__header">Features</h2>

			<h4 className="home-features__sub-header">
				The Most Important Insights In The Palm Of Your Hand
			</h4>

			<div className="home-features__cards">
				{featureImgData.map((v) => (
					<HomeFeature
						key={v.fileName}
						alt={v.alt}
						description={v.desc}
						img={v.img}
						title={v.title}
					/>
				))}
			</div>

			<a
				className="home-features__btn"
				href="https://support.motiv.team/hc/en-us/requests/new?ticket_form_id=360005736034"
				rel="noreferrer"
				target="_blank"
			>
				Request Demo
			</a>
		</section>
	)
}

type HomeFeatureProps = {
	readonly img: IGatsbyImageData
	readonly alt: string
	readonly description: string
	readonly title: string
}

const HomeFeature = (p: HomeFeatureProps) => (
	<div className="home-features__card">
		<GatsbyImage image={p.img} alt={p.alt} />
		<h5 className="home-features__card-header">{p.title}</h5>
		<p className="home-features__card-desc">{p.description}</p>
	</div>
)
