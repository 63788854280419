import './NavBar.scss'

import { useFn } from '@eturi/react'
import { useLocation } from '@reach/router'
import cls from 'classnames'
import { Link } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import { useIsInit, useIsScreenLG } from '../../hooks'
import MOBILE_LOGO from '../../images/svg/mobile_logo.svg'
import DESKTOP_LOGO from '../../images/svg/motiv-logo.svg'

type NavBarProps = {
	readonly className?: string
}

export const NavBar = ({ className }: NavBarProps) => {
	const isInit = useIsInit()
	const isScreenLG = useIsScreenLG()
	const [isMobileMenuVisible, setMobileMenuVisible] = useState(false)

	const location = useLocation()
	const hideDownloadBtn = location.pathname.includes('/download')

	// If screen resizes and mobile menu was open we close it
	useEffect(() => {
		isScreenLG && setMobileMenuVisible(false)
	}, [isScreenLG])

	const showMobileMenu = useFn(() => {
		setMobileMenuVisible(!isMobileMenuVisible)
	})

	const MOBILE_MENU_BTN = useMemo(
		() =>
			!isScreenLG &&
			isInit && (
				<button className="nav-bar__mobile-menu-btn" onClick={showMobileMenu}>
					<i className="m-hamburger" />
				</button>
			),
		[isScreenLG, isInit],
	)

	const MOBILE_MENU = useMemo(
		() =>
			!isScreenLG &&
			isInit && (
				<section
					className={cls(
						'nav-bar-mobile-menu',
						isMobileMenuVisible && 'nav-bar-mobile-menu--visible',
					)}
				>
					<div className="nav-bar__mobile-menu-btn-wrap">{MOBILE_MENU_BTN}</div>

					<Link className="nav-bar-mobile-menu__link" to="/about">
						About
					</Link>

					<a
						className="nav-bar-mobile-menu__link"
						href="https://support.motiv.team/"
						rel="noreferrer"
						target="_blank"
					>
						FAQ
					</a>

					<Link className="nav-bar-mobile-menu__link" to="/pricing">
						Pricing
					</Link>

					<a
						className="nav-bar-mobile-menu__link"
						href="https://support.motiv.team/hc/en-us/requests/new?ticket_form_id=1500000087062"
						rel="noreferrer"
						target="_blank"
					>
						Contact
					</a>

					<a
						className="nav-bar-mobile-menu__link"
						href="https://app.motiv.team/login"
						rel="noreferrer"
						target="_blank"
					>
						Login
					</a>
				</section>
			),
		[isMobileMenuVisible, isScreenLG, isInit],
	)

	return (
		<div className={cls('nav-bar-container', className)}>
			<nav className="nav-bar">
				<figure className="nav-bar__logo">
					<Link to="/">
						<img alt="Motiv Logo" className="nav-bar__logo-img" src={MOBILE_LOGO} />
						<img
							alt="Motiv Logo"
							className="nav-bar__logo-img nav-bar__logo-img--lg"
							src={DESKTOP_LOGO}
						/>
					</Link>
				</figure>

				<section className="nav-bar__links">
					<section className="nav-bar__page-links">
						<Link className="nav-bar__link" to="/about">
							About
						</Link>

						<a
							className="nav-bar__link"
							href="https://support.motiv.team/"
							rel="noreferrer"
							target="_blank"
						>
							FAQ
						</a>

						<Link className="nav-bar__link" to="/pricing">
							Pricing
						</Link>

						<a
							className="nav-bar__link"
							href="https://support.motiv.team/hc/en-us/requests/new?ticket_form_id=1500000087062"
							rel="noreferrer"
							target="_blank"
						>
							Contact
						</a>

						<a
							className="nav-bar__link"
							href="https://app.motiv.team/login"
							rel="noreferrer"
							target="_blank"
						>
							Login
						</a>
					</section>

					<a
						className="nav-bar__btn-link nav-bar__btn-link--transparent"
						href="https://app.motiv.team/signup"
						rel="noreferrer"
						target="_blank"
					>
						Sign Up
					</a>

					{!hideDownloadBtn && (
						<Link className="nav-bar__btn-link nav-bar__btn-link--primary" to="/download">
							Download
						</Link>
					)}

					{MOBILE_MENU_BTN}
				</section>
			</nav>

			{isMobileMenuVisible && <div className="nav-bar__mobile-menu-background" />}

			{MOBILE_MENU}
		</div>
	)
}
