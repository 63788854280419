import './About.scss'

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const About = () => {
	return (
		<div className="about-page">
			<div className="about-page__hero-wrap">
				<StaticImage
					alt="People collaborating background"
					className="about-page__hero-img"
					src="../../images/about-hero.png"
					layout="fullWidth"
					objectPosition="right center"
					avifOptions={{ quality: 65 }}
					webpOptions={{ quality: 87 }}
				/>

				<div className="about-page__hero">
					<h1 className="about-page__hero-header">Insight not Oversight</h1>
				</div>
			</div>

			<div className="about-page__content content">
				<div className="about-page__content-diagram">
					<StaticImage
						alt="People engaging in business collaborations"
						className="about-page__content-img"
						src="../../images/about.png"
						avifOptions={{ quality: 45 }}
						webpOptions={{ quality: 55 }}
					/>
				</div>

				<div className="about-page__content-info">
					<h2>
						Our Motiv is to ensure CEOs and Business Leaders have the productivity metrics for their
						remote Team Leaders
					</h2>

					<p className="about-page__content-desc">
						Motiv is the brainchild of Eturi, a leader in mobile platform management solutions.
						Motiv builds off of the expertise garnered from developing the world’s leading parental
						control app, OurPact. Now, Eturi is addressing the need for business leaders to have
						greater access to productivity metrics anywhere.
					</p>

					<p className="about-page__content-desc">
						With Motiv’s web and mobile app, CEOs, managers and leaders of distributed teams are
						able to see the most important productivity metrics on team leaders. Motiv integrates
						with the apps your business uses everyday, such as Microsoft 365 and Google Workspace.
						And because privacy is important, Motiv requires no plug-ins, and nothing is ever
						installed on company computers.
					</p>
				</div>
			</div>

			<div className="about-page__mission">
				<h3 className="about-page__mission-header">Our Mission</h3>

				<h4 className="about-page__mission-sub-header">
					To Empower Business Leaders with High-Quality, Useful Productivity Metrics on Team
					Leaders.
				</h4>
			</div>
		</div>
	)
}
