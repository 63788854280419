import type UAParser from 'ua-parser-js'

export const toMajorMinorVersion = (v: Maybe<string>): string => {
	if (!v) return ''

	const splitVersion = v.split('.')
	// Handles just major version i.e. 1
	if (splitVersion.length === 1) return v

	return splitVersion[0] + '.' + splitVersion[1]
}

export const isMobileDevice = (info: UAParser.IDevice, ua: string) =>
	(info.type && /mobile|tablet/.test(info.type)) ||
	/Mobile|Android|OurPact_Android|iP(ad|od|hone)|Fennec|mini/.test(ua)

const isIOSModel = (name: string): boolean => name === 'iOS'

const isIPadAmbiguousSafari = ({ name, version }: UAParser.IBrowser): boolean => {
	if (!(name && version)) return false

	let touchEnabled = false
	// If touch is enabled then we can determine its iPad, if Mac ever gets
	// touch then this will fail
	try {
		document.createEvent('TouchEvent')
		touchEnabled = true
	} catch (e) {
		//
	}

	return (
		name.toLowerCase() === 'safari' && Number(toMajorMinorVersion(version)) >= 13 && touchEnabled
	)
}

export const isIOS = (os: UAParser.IOS, browser: UAParser.IBrowser): boolean =>
	isIPadAmbiguousSafari(browser) || isIOSModel(os.name || '')
