import './HomeMobileCarousel.scss'

import {
	IOS_DEBOUNCE_SCROLL,
	PASSIVE_CAPTURE,
	useDebounce,
	useFn,
	useStateGetter,
	useWindowEvent,
} from '@eturi/react'
import { a, useSpring } from '@react-spring/web'
import cls from 'classnames'
import React, { useEffect, useRef } from 'react'
import { unstable_batchedUpdates as bup } from 'react-dom'

type HomeMobileCarouselProps = {
	readonly activeIdx: number
	readonly setActiveIdx: (index: number) => void
}

export const HomeMobileCarousel = ({ activeIdx, setActiveIdx }: HomeMobileCarouselProps) => {
	const menuItemRef = useRef<HTMLDivElement>(null)

	const [menuContainWidth, setMenuContainerWidth] = useStateGetter(0)
	const [menuItemWidth, setMenuItemWidth] = useStateGetter(0)

	const showPreviousBtn = activeIdx > 0
	const showNextBtn = activeIdx < 3

	const [{ x }, setSpring] = useSpring(() => ({
		config: { clamp: true },
		x: 0,
	}))

	const calcAndSetX = useFn(() => {
		let x

		if (activeIdx === 0) {
			// First item, we keep everything on the left
			x = 0
		} else if (activeIdx === 3) {
			// Last item, we move to "max right", which is the width of all items - the container
			x = -1 * (menuItemWidth() * 4 - window.innerWidth)
		} else {
			// Otherwise we center the item
			x = menuContainWidth() / 2 - activeIdx * menuItemWidth() - menuItemWidth() / 2
		}

		setSpring({ x })
	})

	const measure = () => {
		const $menuItem = menuItemRef.current

		if (!$menuItem) return

		bup(() => {
			setMenuContainerWidth(window.innerWidth)
			setMenuItemWidth($menuItem.offsetWidth)
		})
	}

	const handlePrevClick = useFn(() => {
		setActiveIdx(activeIdx - 1)
	})

	const handleNextClick = useFn(() => {
		setActiveIdx(activeIdx + 1)
	})

	useEffect(calcAndSetX, [activeIdx])
	useEffect(measure, [menuItemRef.current])

	useWindowEvent(
		'resize',
		useDebounce(() => {
			measure()
			calcAndSetX()
		}, IOS_DEBOUNCE_SCROLL),
		PASSIVE_CAPTURE,
	)

	return (
		<div className="mobile-carousel">
			{showPreviousBtn && (
				<button className="mobile-carousel__btn-left" onClick={handlePrevClick}>
					<i className="m-chevron-left" />
				</button>
			)}

			<a.div
				className="mobile-carousel__tabs"
				style={{ transform: x.to((x: number) => `translate(${x}px, 0px)`) }}
			>
				<div ref={menuItemRef} className="mobile-carousel__tab-container">
					<div
						className={cls(
							'mobile-carousel__tab',
							activeIdx === 0 && 'mobile-carousel__tab--active',
						)}
					>
						<p className="mobile-carousel__tab-header">Create & Integrate</p>
						<p>Connect Motiv directly with Google Apps or Microsoft 365</p>
					</div>
				</div>

				<div className="mobile-carousel__tab-container">
					<div
						className={cls(
							'mobile-carousel__tab',
							activeIdx === 1 && 'mobile-carousel__tab--active',
						)}
					>
						<p className="mobile-carousel__tab-header">Invite Team Leads</p>
						<p>Use the web app to add your leads and give them to Motiv</p>
					</div>
				</div>

				<div className="mobile-carousel__tab-container">
					<div
						className={cls(
							'mobile-carousel__tab',
							activeIdx === 2 && 'mobile-carousel__tab--active',
						)}
					>
						<p className="mobile-carousel__tab-header">Create & Assign Teams</p>
						<p>Assign your teams to your team leads using our web app.</p>
					</div>
				</div>

				<div className="mobile-carousel__tab-container">
					<div
						className={cls(
							'mobile-carousel__tab',
							activeIdx === 3 && 'mobile-carousel__tab--active',
						)}
					>
						<p className="mobile-carousel__tab-header">Download The App</p>
						<p>Download the Motiv mobile app and get going!</p>
					</div>
				</div>
			</a.div>

			{showNextBtn && (
				<button className="mobile-carousel__btn-right" onClick={handleNextClick}>
					<i className="m-chevron-right" />
				</button>
			)}
		</div>
	)
}
