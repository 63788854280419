import { useEffect, useState } from 'react'

/**
 * Forces an initial rerender on updating state on mount
 */
export const useIsInit = (): boolean => {
	const [isInit, setIsInit] = useState(false)

	useEffect(() => {
		setIsInit(true)
	}, [])

	return isInit
}
