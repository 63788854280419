import './Error.scss'

import { Link } from 'gatsby'
import React from 'react'
import { NavBar } from '../NavBar'

export const Error = () => {
	return (
		<>
			<NavBar />
			<div className="error-page">
				<div className="error-page__heading">
					<h1 className="error-page__four">4</h1>

					<div className="error-page__zero">
						<p className="error-page__zero-sub-heading">100%</p>
						<p className="error-page__zero-heading">Page Not Found</p>
					</div>

					<h1 className="error-page__four">4</h1>
				</div>

				<div className="error-page__content">
					<p className="error-page__content-info">
						Sorry, the page you were looking for could not be found
					</p>
					<Link className="error-page__btn" to="/">
						Return Home
					</Link>
				</div>

				<div className="error-page__copyrights">
					<p className="error-page__copyright">Copyright 2021 Eturi Corp.</p>
					<a className="error-page__terms">Terms & Conditions</a>
				</div>
			</div>
		</>
	)
}
