import { useMemo } from 'react'
import { UAParser } from 'ua-parser-js'
import { isIOS, isMobileDevice } from '../utils'
import { useIsInit } from './useIsInit'

export const useIsMobile = (): [isMobile: boolean, isIOS: boolean] => {
	const isInit = useIsInit()

	return useMemo(() => {
		if (!isInit) return [false, false]

		const parser = new UAParser(navigator.userAgent)

		return [
			isMobileDevice(parser.getDevice(), navigator.userAgent),
			isIOS(parser.getOS(), parser.getBrowser()),
		]
	}, [isInit])
}
