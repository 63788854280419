import './PricingFeatures.scss'

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import GOOGLE_LOGO from '../../images/svg/google-logo.svg'
import GOOGLE_MEETS_LOGO from '../../images/svg/google-meet-logo.svg'
import MICROSOFT_LOGO from '../../images/svg/microsoft-logo.svg'

export const PricingFeatures = () => (
	<div className="pricing-feat">
		<div className="pricing-feat-info">
			<div className="pricing-feat-info__desc">
				<h2 className="pricing-feat-info__heading">Features</h2>

				<ul className="pricing-feat-info__list">
					<li>In-Depth schedule analytics</li>
					<li>Digital collaboration insights</li>
					<li>Email overview</li>
					<li>Google Meet attendance data</li>
					<li>Data analysis by teams and individuals</li>
				</ul>
			</div>

			<div className="pricing-feat-info__img">
				<StaticImage
					src="../../images/pricing-features.png"
					alt="App connecting coworkers"
					objectFit="contain"
					objectPosition="0 0"
				/>
			</div>
		</div>

		<div className="pricing-feat-integration">
			<h2 className="pricing-feat-integration__heading">
				{`Integrate with your company's tools.`}
			</h2>

			<div className="pricing-feat-integration__list">
				<IntegrationItem logo={GOOGLE_LOGO} title="Google Workspace" />
				<IntegrationItem logo={GOOGLE_MEETS_LOGO} title="Google Meet" />
				<IntegrationItem logo={MICROSOFT_LOGO} title="Microsoft 365" />
			</div>
		</div>
	</div>
)

type IntegrationItemProps = {
	readonly logo: string
	readonly title: string
}

const IntegrationItem = ({ logo, title }: IntegrationItemProps) => (
	<div className="pricing-feat-integration-item">
		<img className="pricing-feat-integration-item__logo" src={logo} alt={`${title} Logo`} />
		<h6 className="pricing-feat-integration-item__title">{title}</h6>
	</div>
)
