import './PricingTeamData.scss'

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const PricingTeamData = () => (
	<div className="pricing-team-data">
		<h2 className="pricing-team-data__header">Team & Individual Data</h2>

		<div className="pricing-team-data__info">
			<div>
				<h2>Create as many teams as you want.</h2>

				<p>
					Review how your teams are working together. Give your leaders the ability to track
					collaboration.
				</p>
			</div>
		</div>

		<div className="pricing-team-data__img-container">
			<StaticImage
				className="pricing-team-data__img pricing-team-data__left-img-mobile"
				src="../../images/pricing-team-mobile-1.png"
				alt="Executive view of Motiv App"
				objectFit="contain"
			/>

			<StaticImage
				className="pricing-team-data__img pricing-team-data__left-img"
				src="../../images/pricing-team-1.png"
				alt="Executive view of Motiv App"
				objectFit="contain"
				avifOptions={{ quality: 75 }}
				webpOptions={{ quality: 86 }}
			/>

			<div className="pricing-team-data__center-container">
				<div className="pricing-team-data__center-text">
					<h2>Drill down to each member</h2>
					<p>Review productivity data for individual team members.</p>
				</div>

				<div className="pricing-team-data__center-img" />
			</div>

			<StaticImage
				className="pricing-team-data__img pricing-team-data__right-img"
				src="../../images/pricing-team-2.png"
				alt="Team member motiv info"
				objectFit="contain"
				avifOptions={{ quality: 65 }}
				webpOptions={{ quality: 85 }}
			/>
		</div>
	</div>
)
