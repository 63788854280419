/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

type SEOProps = {
	readonly description?: string
	readonly lang?: string
	readonly meta?: any[]
	readonly title: string
}

export const SEO = ({ description = '', lang = 'en', meta = [], title }: SEOProps) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`,
	)

	const metaDescription = description || site.siteMetadata.description
	const defaultTitle = site.siteMetadata?.title

	// We must do this check since gatsby is SSR and window doesn't exist in the server
	const dataLayer = typeof window !== 'undefined' ? (window as any)?.dataLayer || [] : []

	// Once dataLayer has changed/init we push the values we want to it
	useEffect(() => {
		function gtag(...args: any[]) {
			dataLayer.push(args)
		}
		gtag('js', new Date())
		gtag('config', 'G-D0PWFTTJ6F')
	}, [dataLayer])

	return (
		<Helmet
			defer={false}
			htmlAttributes={{ lang }}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata?.author || ``,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				...meta,
			]}
		>
			<script
				type="text/javascript"
				id="hs-script-loader"
				async
				defer
				src="//js.hs-scripts.com/20723876.js"
			/>
			<script async src="https://www.googletagmanager.com/gtag/js?id=G-D0PWFTTJ6F" />
		</Helmet>
	)
}
